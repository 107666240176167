import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import { Home } from './Home';
import { theme } from '../components/theme';
import { Callback } from './Callback';

export const AuthRouting = ({ isAuthStateChanged }) => {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/callback">
          <Callback />
        </Route>
        <Route path="/">
          <Home isAuthStateChanged={isAuthStateChanged}></Home>
        </Route>
      </Switch>
    </ThemeProvider>
  );
};
AuthRouting.propTypes = { isAuthStateChanged: PropTypes.bool };
