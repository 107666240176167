import { BRAIN_LOCATION, TRACKING_TYPES } from '../constants/guideline';
import { math } from '../../../modules/dicom-measurement/src';
const { round } = math;

export function getMeasurementSource(timepoints, ROIContours) {
  if (timepoints.length === 0) {
    return;
  }
  const dataSource = {
    data: [],
    columns: [
      {
        dataField: 'patientId',
        displayName: 'Patient ID',
      },
      {
        dataField: 'date',
        displayName: 'Scan Date',
      },
      {
        dataField: 'ROIName',
        displayName: 'Name',
      },
      {
        dataField: 'ROILocation',
        displayName: 'Location',
      },
      {
        dataField: 'measurable',
        displayName: 'Measurability',
      },
      {
        dataField: 'trackingType',
        displayName: 'Type',
      },
      {
        dataField: 'status',
        displayName: 'Status',
      },
      {
        dataField: 'volume',
        displayName: 'Volume (mm^3)',
      },
      {
        dataField: 'longAxis',
        displayName: 'Long Axis (mm)',
      },
      {
        dataField: 'shortAxis',
        displayName: 'Short Axis (mm)',
      },
      {
        dataField: 'locationX',
        displayName: 'Location X',
      },
      {
        dataField: 'locationY',
        displayName: 'Location Y',
      },
      {
        dataField: 'imageIdx',
        displayName: 'Image Index',
      },
    ],
  };

  timepoints.forEach(timepoint => {
    timepoint.Lesions.forEach((lesion, index) => {
      const name = lesion.ROIName;
      const key = Object.keys(BRAIN_LOCATION).find(l => name.includes(l));
      const location = BRAIN_LOCATION[key] || 'N/A';
      dataSource.data.push({
        patientId: timepoint.PatientID,
        date: timepoint.StudyDate,
        ROIName: name,
        ROILocation: location,
        measurable: ROIContours[index].measurable
          ? 'Measurable'
          : 'Non-measurable',
        trackingType: ROIContours[index].trackingType,
        status: [
          TRACKING_TYPES.TARGET,
          TRACKING_TYPES.NONTARGET,
          TRACKING_TYPES.NEW,
        ].includes(ROIContours[index].trackingType)
          ? lesion.volume > 0
            ? 'Present'
            : 'Absent'
          : '',
        volume: round(lesion.volume, -2),
        longAxis: round(lesion.maxLongAxisValue, -2),
        shortAxis: round(lesion.maxShortAxisValue, -2),
        locationX: round(lesion.maxAreaCentroid.x, -2),
        locationY: round(lesion.maxAreaCentroid.y, -2),
        imageIdx: lesion.InstanceNumber,
        physicalCoordinate: `${lesion.maxAreaPhysicalCentroid.x}\\${lesion.maxAreaPhysicalCentroid.y}\\${lesion.maxAreaPhysicalCentroid.z}`,
      });
    });
  });
  return dataSource;
}
