import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import init from './modules/init';
import commandsModule from './modules/commandsModule/commandsModule';
import toolbarModule from './modules/toolbarModule/toolbarModule';
import { ViewportController } from './components/ViewportController';

const Viewport = lazy(() => import('./modules/viewportModule/Viewport'));
const MprViewport = lazy(() => import('./modules/viewportModule/MprViewport'));

export { ViewportController };
export default {
  id: 'cornerstone',
  /**
   * @param {object} [configuration={}]
   * @param {object|array} [configuration.csToolsConfig] - Passed directly to `initCornerstoneTools`
   */
  preRegistration({ servicesManager, configuration = {}, appConfig = {} }) {
    init({ servicesManager, configuration, appConfig });
  },
  getViewportModule({ commandsManager, appConfig }) {
    const ExtendedViewport = props => {
      const onNewImageHandler = jumpData => {
        /** Do not trigger all viewports to render unnecessarily */
        jumpData.refreshViewports = false;
        commandsManager.runCommand('jumpToImage', jumpData);
      };

      const { studyPrefetcher } = appConfig;
      const isStackPrefetchEnabled =
        studyPrefetcher && !studyPrefetcher.enabled;

      if (props.viewportData.displaySet.isMpr) {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <MprViewport {...props} isStackPrefetchEnabled={false} />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Viewport
            {...props}
            onNewImage={onNewImageHandler}
            isStackPrefetchEnabled={isStackPrefetchEnabled}
          />
        </Suspense>
      );
    };
    ExtendedViewport.propTypes = {
      viewportData: PropTypes.any,
    };
    return ExtendedViewport;
  },
  getToolbarModule({ commandsManager }) {
    return toolbarModule({ commandsManager });
  },
  getCommandsModule({ servicesManager }) {
    return commandsModule({ servicesManager });
  },
};
