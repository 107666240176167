import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import styled from 'styled-components';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';

import { AppContext, APIContext } from '@platform/viewer';
import { utils, redux, guidelines, Firebase } from '@platform/core';
import { LoadingSpinner, useLogger, useSnackbar } from '@platform/ui';
import theme from './theme';
import RTEdit from './RTEdit/RTEdit';
import * as ls from './LongitudinalStudy';

const { actions } = redux;

export function ExtendedRTPanel(props) {
  const { studies, isStudyLoaded } = props;
  /** contexts */
  const { appConfig: config, mode } = useContext(AppContext);
  const api = useContext(APIContext);
  /** states */
  const viewports = useSelector(state => state.viewports);
  const { dicomRT } = useSelector(state => state.extensions);
  const { seriesStructureMap, selectedStructureSetUID } = dicomRT;
  const [panelMode, setPanelMode] = useState('default');
  /** hooks */
  const dispatch = useDispatch();
  /** queries */
  const { params } = useRouteMatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const uidsString = query.get('uids') || '';
  const uids = getUIDs(uidsString);
  const glString = query.get('guideline') || '';
  const loadingSets = getLoadingStructureSets(studies, isStudyLoaded, uids);
  /** services */
  const snackbar = useSnackbar();
  const logger = useLogger();
  const loadStructureSets = async displaySets => {
    const apiConfig = api?.isEnabled() ? api.getConfig() : {};
    const loaded = [];
    for (const displaySet of displaySets) {
      try {
        await displaySet.load(studies, displaySet, { ...config, ...apiConfig });
        loaded.push(displaySet);
        if (mode !== 'tracking' && uids.length === 0) break;
      } catch (error) {
        logger.error({
          message: `Can't load DICOM RT structure set`,
          event_type: 'Viewer:load',
          details: {
            pathname: location.pathname,
            structure_set_study_uid: displaySet.StudyInstanceUID,
            structure_set_series_uid: displaySet.SeriesInstanceUID,
            structure_set_instance_uid: displaySet.SOPInstanceUID,
          },
        });
        snackbar.show({
          title: 'DICOM RTSTRUCT',
          message: `Can't load structure set ${displaySet.SeriesInstanceUID}`,
          type: 'error',
          autoClose: false,
        });
      }
    }
    const { pathname } = location;
    const imageSets = utils.getImageSetsFromStudies(studies);
    const axialImageSets = imageSets.filter(set => set.viewType === 'axial');
    const series = [...axialImageSets, ...loaded].map(set => ({
      study_uid: set.StudyInstanceUID,
      series_uid: set.SeriesInstanceUID,
    }));
    logger.info({
      message: `DICOM files successfully loaded`,
      event_type: 'Viewer:load',
      details: { pathname, mode, series, config: apiConfig },
    });
    return loaded;
  };

  useEffect(() => {
    if (studies.length === 0 || !isStudyLoaded) return;
    if (panelMode !== 'default') return;
    /** init structure set */
    (async () => {
      const loaded = await loadStructureSets(loadingSets);
      const structureSets = loadingSets.map(set => ({
        SeriesInstanceUID: set.SeriesInstanceUID,
        SeriesDescription: set.SeriesDescription,
        SeriesDate: set.SeriesDate,
        SeriesTime: set.SeriesTime,
        isLoaded: set.isLoaded,
      }));
      dispatch(actions.setStructureSets(structureSets));
      const seriesStructureMap = initStructureSets(studies, loaded);
      dispatch(actions.setSeriesStructureMap(seriesStructureMap));
      if (mode === 'tracking') {
        await initTracking(loaded);
      }
      if (mode === 'longitudinal') {
        await initLongitudinalStudy(studies, loaded, params, glString);
      }
      const panelMode = 'edit';
      const module = cornerstoneTools.getModule('rtstruct-edit');
      module.setters.mode(panelMode);
      setPanelMode(panelMode);
    })();
    return () => {
      const editModule = cornerstoneTools.getModule('rtstruct-edit');
      editModule.setters.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies, isStudyLoaded]);

  useEffect(() => {
    /** auto switch structure set */
    if (!seriesStructureMap) return;
    const { viewportSpecificData, activeViewportIndex } = viewports;
    const viewport = viewportSpecificData[activeViewportIndex];
    const { SeriesInstanceUID } = viewport || {};
    const structureSetUID = seriesStructureMap[SeriesInstanceUID];
    const editModule = cornerstoneTools.getModule('rtstruct-edit');
    editModule.setters.selectedStructureSetUID(structureSetUID);
    dispatch(actions.setSelectedStructureSetUID(structureSetUID));
  }, [
    dispatch,
    seriesStructureMap,
    viewports,
    viewports.activeViewportIndex,
    viewports.viewportSpecificData,
  ]);

  if (isStudyLoaded && panelMode !== 'default') {
    const { viewportSpecificData, activeViewportIndex } = viewports;
    const viewport = viewportSpecificData[activeViewportIndex];
    const isStructureSetLoaded =
      loadingSets.length > 0 && !!selectedStructureSetUID;
    return (
      <ThemeProvider theme={theme}>
        {viewport?.SeriesInstanceUID && (
          <RTPanel data-cy="rt-panel">
            <SubPanel value={panelMode} index={'edit'}>
              <RTEdit {...props} isLoaded={isStructureSetLoaded} />
            </SubPanel>
          </RTPanel>
        )}
      </ThemeProvider>
    );
  }
  return (
    <div style={{ margin: '15px' }}>
      <LoadingSpinner />
    </div>
  );
}
ExtendedRTPanel.propTypes = {
  studies: PropTypes.array,
  isStudyLoaded: PropTypes.bool,
};

const RTPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
  padding: 20px;
`;

const SubPanel = props => {
  const { children, value, index } = props;
  return value === index && children;
};

function getUIDs(uidsString) {
  if (!uidsString) return [];
  const series = uidsString.split(',').map(i => {
    const [StudyInstanceUID, SeriesInstanceUID] = i.split(':');
    return { StudyInstanceUID, SeriesInstanceUID };
  });
  const uids = series.map(s => s.SeriesInstanceUID);
  return uids;
}

function getLoadingStructureSets(studies, isStudyLoaded, uids) {
  if (studies.length === 0 || !isStudyLoaded) return [];
  const rtstructDisplaySets = studies
    .reduce((displaySets, study) => [...displaySets, ...study.displaySets], [])
    .filter(set => set.Modality === 'RTSTRUCT')
    .filter(set => uids.length === 0 || uids.includes(set.SeriesInstanceUID));
  const sortedRtstructDisplaysets = _.chain(rtstructDisplaySets)
    .sortBy(displayset => {
      const timestamp = utils.getTimestampFromMetadata(
        displayset.SeriesDate,
        displayset.SeriesTime,
        displayset.metadata.TimezoneOffsetFromUTC
      );
      return -timestamp;
    })
    .value();
  return sortedRtstructDisplaysets;
}

function initStructureSets(studies, displaySets) {
  if (studies.length === 0 || displaySets.length === 0) return;
  const { globalImageIdSpecificToolStateManager } = cornerstoneTools;
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const imageSets = utils.getImageSetsFromStudies(studies);
  const axialImageSets = imageSets.filter(set => set.viewType === 'axial');
  const seriesStructuresMap = {};

  for (const displaySet of displaySets) {
    const { StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID } = displaySet;
    const ROIContours = [];
    const referencedSeriesSequence = [];
    const referencedFrameOfReferenceSequence = [];

    /** copy metadata */
    const module = cornerstoneTools.getModule('rtstruct-edit');
    const defaultStructureSet = module.getters.rawStructureSet(
      SeriesInstanceUID
    );
    if (defaultStructureSet) {
      ROIContours.push(..._.cloneDeep(defaultStructureSet.ROIContours));
      referencedFrameOfReferenceSequence.push(
        ..._.cloneDeep(defaultStructureSet.referencedFrameOfReferenceSequence)
      );
    }

    /** check referenced series --> add data */
    const imageTools = globalImageIdSpecificToolStateManager.saveToolState();
    axialImageSets.forEach(imageSet => {
      const existSequence = !!defaultStructureSet.referencedSeriesSequence;
      const existReferencedSeries = defaultStructureSet.referencedSeriesSequence?.find(
        sq => sq.SeriesInstanceUID === imageSet.SeriesInstanceUID
      );
      if (existSequence && !existReferencedSeries) return;
      referencedSeriesSequence.push({
        SeriesInstanceUID: imageSet.SeriesInstanceUID,
        ReferencedInstanceSequence: imageSet.images.map(image => ({
          ReferencedSOPClassUID: image.getData().metadata.SOPClassUID,
          ReferencedSOPInstanceUID: image.getSOPInstanceUID(),
        })),
      });
      for (const image of imageSet.images) {
        const imageId = image.getImageId();
        let _data = [];
        if (imageTools[imageId]) {
          const data = imageTools[imageId].RTStructDisplayTool.data.filter(
            data => data.structureSetSeriesInstanceUid === SeriesInstanceUID
          );
          _data = _.cloneDeep(data);
        }
        /** series-rtstruct match */
        const painter = editModule.setters.createPainter(
          'dummy',
          imageId,
          SeriesInstanceUID,
          { data: _data, SeriesInstanceUID }
        );
        painter.commit();
      }
      seriesStructuresMap[imageSet.SeriesInstanceUID] = SeriesInstanceUID;
    });

    const structureSet = {
      ROIContours,
      StudyInstanceUID,
      SeriesInstanceUID,
      SOPInstanceUID,
      referencedSeriesSequence,
      referencedFrameOfReferenceSequence,
      visible: defaultStructureSet.visible,
    };
    editModule.setters.structureSet(structureSet);
  }

  return seriesStructuresMap;
}

async function initTracking(displaySets) {
  const trackingTypes = { TRACKED: 'tracked', NEW: 'new', EX: 'ex' };
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  for (const displaySet of displaySets) {
    const { SeriesInstanceUID } = displaySet;
    const structureSet = editModule.getters.structureSet(SeriesInstanceUID);
    const { ROIContours } = structureSet;
    /** parse observation label */
    const _ROIContours = ROIContours.map(roi => {
      roi.trackingType = 'none';
      const { RTROIObservations } = roi;
      if (!RTROIObservations) return roi;
      const { ROIObservationLabel } = RTROIObservations;
      roi.trackingType = trackingTypes[ROIObservationLabel] || 'none';
      return roi;
    });
    editModule.setters.ROIContours(SeriesInstanceUID, _ROIContours);
  }
}

async function initLongitudinalStudy(studies, displaySets, params, query) {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const imageSets = utils.getImageSetsFromStudies(studies);
  const axialImageSets = imageSets.filter(set => set.viewType === 'axial');
  try {
    /** set RECIST 1.1 as default criteria */
    const criteria = guidelines[query] || guidelines['RECIST11'];
    editModule.setters.criteria(criteria);

    /** get trial criteria from firestore */
    const trialCriteria = await Firebase.getTrialCriteria(params);
    if (Object.keys(guidelines).includes(trialCriteria?.guidelineId)) {
      editModule.setters.criteria(trialCriteria);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
  try {
    /** set default assessments */
    const assessments = axialImageSets.map(set => ({
      SeriesInstanceUID: set.SeriesInstanceUID,
      /** define series date */
      SeriesDate:
        set.SeriesDate || set.ContentDate || set.AcquisitionDate || '',
      overallResponse: '',
      targetResponse: '',
      nonTargetResponse: '',
      newLesionResponse: '',
      steroidsUse: '',
      clinicalStatus: '',
    }));
    editModule.setters.assessments(assessments);

    /** get read assessments from firestore */
    const readAssessments = await Firebase.getReadAssessments(params);
    if (readAssessments?.length > 0) {
      editModule.setters.assessments(readAssessments);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
  try {
    /** classify roi contours by tracking type in a longitudinal study */
    const criteria = editModule.getters.criteria();
    for (const displaySet of displaySets) {
      const { SeriesInstanceUID } = displaySet;
      const structureSet = editModule.getters.structureSet(SeriesInstanceUID);
      const { ROIContours } = structureSet;
      const { BODY_PARTS, TRACKING_TYPES } = ls.guideline;
      /** parse observation label */
      ROIContours.forEach(roi => {
        roi.bodyPart = BODY_PARTS.OTHERS;
        roi.measurable = false;
        roi.trackingType = TRACKING_TYPES.NONE;
        const { RTROIObservations } = roi;
        if (!RTROIObservations) return;
        const { ROIObservationLabel } = RTROIObservations;
        if (!ROIObservationLabel) return;
        const [bodyPart, trackingType] = ROIObservationLabel.split('_');
        if (bodyPart && BODY_PARTS[bodyPart]) {
          roi.bodyPart = BODY_PARTS[bodyPart];
        }
        if (trackingType && TRACKING_TYPES[trackingType]) {
          roi.trackingType = TRACKING_TYPES[trackingType];
        }
      });
      const timepoints = ls.getTimepoints(axialImageSets, ROIContours);
      const classifiedROIContours = ls.getClassifiedROIContours(
        timepoints,
        ROIContours,
        criteria,
        false
      );
      editModule.setters.ROIContours(SeriesInstanceUID, classifiedROIContours);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}
