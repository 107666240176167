/** Action Creators:
 *  https://redux.js.org/basics/actions#action-creators
 */

import {
  CLEAR_VIEWPORT,
  SET_VIEWPORT,
  SET_VIEWPORT_ACTIVE,
  SET_VIEWPORT_LAYOUT,
  SET_VIEWPORT_LAYOUT_AND_DATA,
} from './constants/ActionTypes.js';

/**
 * The definition of a viewport layout.
 *
 * @typedef {Object} ViewportLayout
 * @property {number} numRows -
 * @property {number} numColumns -
 * @property {array} viewports -
 */

/**
 * VIEWPORT
 */
export const setViewportSpecificData = (
  viewportIndex,
  viewportSpecificData
) => ({
  type: SET_VIEWPORT,
  viewportIndex,
  viewportSpecificData,
});

export const setViewportActive = viewportIndex => ({
  type: SET_VIEWPORT_ACTIVE,
  viewportIndex,
});

/**
 * @param {ViewportLayout} layout
 */
export const setLayout = ({ numRows, numColumns, viewports }) => ({
  type: SET_VIEWPORT_LAYOUT,
  numRows,
  numColumns,
  viewports,
});

/**
 * @param {number} layout.numRows
 * @param {number} layout.numColumns
 * @param {array} viewports
 */
export const setViewportLayoutAndData = (
  { numRows, numColumns, viewports },
  viewportSpecificData
) => ({
  type: SET_VIEWPORT_LAYOUT_AND_DATA,
  numRows,
  numColumns,
  viewports,
  viewportSpecificData,
});

export const clearViewportSpecificData = viewportIndex => ({
  type: CLEAR_VIEWPORT,
  viewportIndex,
});

/**
 * NOT-VIEWPORT
 */
export const setStudyData = (StudyInstanceUID, data) => ({
  type: 'SET_STUDY_DATA',
  StudyInstanceUID,
  data,
});

/**
 * USER
 */

export const setEmail = email => ({
  type: 'SET_EMAIL',
  payload: { email },
});

/**
 * CORNERSTONE
 */
export const setScrollSync = isToggled => ({
  type: 'SET_SCROLL_SYNC',
  payload: { isToggled },
});
export const setPanZoomSync = isToggled => ({
  type: 'SET_PAN_ZOOM_SYNC',
  payload: { isToggled },
});
export const setWwwcSync = isToggled => ({
  type: 'SET_WWWC_SYNC',
  payload: { isToggled },
});

/**
 * RT-PANEL
 */
export const setDICOMRTImageData = (imageId, data) => ({
  type: 'SET_DICOMRT_IMAGE_DATA',
  imageId,
  data,
});
export const setSeriesStructureMap = seriesStructureMap => ({
  type: 'SET_SERIES_STRUCTURE_MAP',
  payload: { seriesStructureMap },
});
export const setStructureSets = structureSets => ({
  type: 'SET_STRUCTURE_SETS',
  payload: { structureSets },
});
export const setSelectedStructureSetUID = selectedStructureSetUID => ({
  type: 'SET_SELECTED_STRUCTURE_SET_UID',
  payload: { selectedStructureSetUID },
});
export const setIsReadOnly = isReadOnly => ({
  type: 'SET_IS_READ_ONLY',
  payload: { isReadOnly },
});
export const setIsComputing = isComputing => ({
  type: 'SET_IS_COMPUTING',
  payload: { isComputing },
});
export const setIsLinking = isLinking => ({
  type: 'SET_IS_LINKING',
  payload: { isLinking },
});
export const setPointLabel = isToggled => ({
  type: 'SET_POINT_LABEL',
  payload: { isToggled },
})

/**
 * TRACKING
 */
export const setTrackingScale = scale => ({
  type: 'SET_TRACKING_SCALE',
  payload: { scale },
});
export const setTrackingWindowWidth = windowWidth => ({
  type: 'SET_TRACKING_WINDOW_WIDTH',
  payload: { windowWidth },
});
export const setTrackingWindowLevel = windowLevel => ({
  type: 'SET_TRACKING_WINDOW_LEVEL',
  payload: { windowLevel },
});
export const setTrackingNotes = notes => ({
  type: 'SET_TRACKING_NOTES',
  payload: { notes },
});

/**
 * LINKAGE
 */
export const setLinkage = linkage => ({
  type: 'SET_LINKAGE',
  payload: { linkage },
});
export const addLink = linkID => ({
  type: 'ADD_LINK',
  payload: { linkID },
});
export const addROILink = (SeriesInstanceUID, ROINumber, linkID) => ({
  type: 'ADD_ROI_LINK',
  payload: { SeriesInstanceUID, ROINumber, linkID },
});
export const updateROILink = (
  SeriesInstanceUID,
  ROINumber,
  prevID,
  nextID
) => ({
  type: 'UPDATE_ROI_LINK',
  payload: { SeriesInstanceUID, ROINumber, prevID, nextID },
});
export const removeROILink = (SeriesInstanceUID, ROINumber) => ({
  type: 'REMOVE_ROI_LINK',
  payload: { SeriesInstanceUID, ROINumber },
});
export const mergeLinks = ids => ({
  type: 'MERGE_LINKS',
  payload: { ids },
});
export const breakLink = linkID => ({
  type: 'BREAK_LINK',
  payload: { linkID },
});
export const removeLinks = ids => ({
  type: 'REMOVE_LINKS',
  payload: { ids },
});

const actions = {
  /**
   * VIEWPORT
   */
  setViewportActive,
  setViewportSpecificData,
  setViewportLayoutAndData,
  setLayout,
  clearViewportSpecificData,
  /**
   * NOT-VIEWPORT
   */
  setStudyData,
  /**
   * USER
   */
  setEmail,
  /**
   * CORNERSTONE
   */
  setScrollSync,
  setPanZoomSync,
  setWwwcSync,
  /**
   * RT-PANEL
   */
  setSeriesStructureMap,
  setStructureSets,
  setSelectedStructureSetUID,
  setDICOMRTImageData,
  setIsReadOnly,
  setIsComputing,
  setIsLinking,
  setPointLabel,
  /**
   * TRACKING
   */
  setTrackingNotes,
  setTrackingScale,
  setTrackingWindowLevel,
  setTrackingWindowWidth,
  /**
   * LINKAGE
   */
  setLinkage,
  addLink,
  addROILink,
  updateROILink,
  removeROILink,
  breakLink,
  mergeLinks,
  removeLinks,
};

export default actions;
