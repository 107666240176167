import isHighPriorityModality from './isHighPriorityModality';

/**
 * Get sorted image sets
 *
 * @param {array} displaySets
 * @return {Array} Array of imageSet object
 */
export default function getSortedImageSetsFromDisplaySets(displaySets) {
  const imageSets = displaySets.filter(displaySet => {
    return isHighPriorityModality(displaySet.Modality);
  });
  sortDisplaySetsByDate(imageSets);
  return imageSets;
}

/**
 * Sort display sets by series date or content date.
 *
 * @param {array} displaySets
 * @returns
 */
export function sortDisplaySetsByDate(displaySets) {
  if (displaySets.length === 0) {
    return;
  }
  displaySets.sort((a, b) => {
    if (!isHighPriorityModality(a.Modality)) {
      return 1;
    } else if (!isHighPriorityModality(b.Modality)) {
      return -1;
    }
    const aSeriesDate =
      a.SeriesDate || a.ContentDate || a.AcquisitionDate || '99999999';
    const bSeriesDate =
      b.SeriesDate || b.ContentDate || b.AcquisitionDate || '99999999';
    return Number(aSeriesDate) - Number(bSeriesDate);
  });
}
