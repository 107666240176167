import cornerstoneTools from 'cornerstone-tools';

import { TRACKING_TYPES, MEASUREMENT_TECHNIQUE } from '../constants/guideline';
import { getAssessedTimepoints } from '../models/getTimepoints';
import {
  getTimepointStats,
  getTargetResponses,
  getNonTargetResponses,
  getNewLesionResponses,
} from '../models/responseAssessments';

export function getAssessmentSource(timepoints, ROIContours) {
  if (timepoints.length === 0) {
    return;
  }
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const criteria = editModule.getters.criteria();
  const assessments = editModule.getters.assessments();

  const dataSource = {
    data: [],
    columns: [
      {
        dataField: 'patientId',
        displayName: 'Patient ID',
      },
      {
        dataField: 'date',
        displayName: 'Scan Date',
      },
      {
        dataField: 'sumOfLesions',
        displayName: 'Sum of All Lesions(mm)',
      },
      {
        dataField: 'sumOfLesionsVolume',
        displayName: 'Sum of All Lesions(mm^3)',
      },
      {
        dataField: 'sumOfTarget',
        displayName: 'Sum of Targets(mm)',
      },
      {
        dataField: 'sumOfTargetVolume',
        displayName: 'Sum of Targets(mm^3)',
      },
      {
        dataField: 'sumOfNonTarget',
        displayName: 'Sum of Non-targets(mm)',
      },
      {
        dataField: 'sumOfNonTargetVolume',
        displayName: 'Sum of Non-targets(mm^3)',
      },
      {
        dataField: 'targetChangeFromBaseline',
        displayName: 'Change of Targets from Baseline(%)',
      },
      {
        dataField: 'targetChangeFromNadir',
        displayName: 'Change of Targets from Nadir(%)',
      },
      {
        dataField: 'nonTargetChangeFromBaseline',
        displayName: 'Change of Non-targets from Baseline(%)',
      },
      {
        dataField: 'nonTargetChangeFromNadir',
        displayName: 'Change of Non-targets from Nadir(%)',
      },
      {
        dataField: 'targetResponse',
        displayName: 'Target Response',
      },
      {
        dataField: 'nonTargetResponse',
        displayName: 'Non-target Response',
      },
      {
        dataField: 'newLesionResponse',
        displayName: 'New Lesion Response',
      },
      {
        dataField: 'steroidsUse',
        displayName: 'Use of Corticosteroids',
      },
      {
        dataField: 'clinicalStatus',
        displayName: 'Clinical Status',
      },
      {
        dataField: 'overallResponse',
        displayName: 'Overall Response',
      },
      {
        dataField: 'measurementTechnique',
        displayName: 'Method of Measurements',
      },
    ],
  };

  const targetROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.TARGET
  );
  const nonTargetROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.NONTARGET
  );
  const newLesionROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.NEW
  );
  const allLesionROIContours = [
    ...targetROIContours,
    ...nonTargetROIContours,
    ...newLesionROIContours,
  ];

  const allLesionStats = getTimepointStats(
    timepoints,
    allLesionROIContours,
    [],
    criteria,
    assessments
  );
  const sumOfLesions = allLesionStats.map(series => series.summation);

  const targetStats = getTimepointStats(
    timepoints,
    targetROIContours,
    [],
    criteria,
    assessments
  );
  const sumOfTarget = targetStats.map(series => series.summation);
  const targetChangeFromBaseline = targetStats.map(
    series => series.fromBaseline
  );
  const targetChangeFromNadir = targetStats.map(series => series.fromNadir);

  const nonTargetStats = getTimepointStats(
    timepoints,
    nonTargetROIContours,
    [],
    criteria,
    assessments
  );
  const sumOfNonTarget = nonTargetStats.map(series => series.summation);
  const nonTargetChangeFromBaseline = nonTargetStats.map(
    series => series.fromBaseline
  );
  const nonTargetChangeFromNadir = nonTargetStats.map(
    series => series.fromNadir
  );

  const targetResponses = getTargetResponses(targetStats, criteria);
  const nonTargetResponses = getNonTargetResponses(nonTargetStats, criteria);
  const newLesionResponses = getNewLesionResponses(
    timepoints,
    newLesionROIContours,
    criteria
  );
  const assessedTimepoints = getAssessedTimepoints(
    timepoints,
    targetResponses,
    nonTargetResponses,
    newLesionResponses,
    assessments
  );

  assessedTimepoints.forEach((timepoint, index) => {
    dataSource.data.push({
      /** data from stats */
      measurementTechnique: MEASUREMENT_TECHNIQUE.BIDIMENSIONAL,
      sumOfLesions: sumOfLesions[index].sumDiameter,
      sumOfLesionsVolume: sumOfLesions[index].sumVolume,
      sumOfTarget: sumOfTarget[index].sumDiameter,
      sumOfTargetVolume: sumOfTarget[index].sumVolume,
      sumOfNonTarget: sumOfNonTarget[index].sumDiameter,
      sumOfNonTargetVolume: sumOfNonTarget[index].sumVolume,
      targetChangeFromBaseline: targetChangeFromBaseline[index].sumDiameter,
      targetChangeFromNadir: targetChangeFromNadir[index].sumDiameter,
      nonTargetChangeFromBaseline:
        nonTargetChangeFromBaseline[index].sumDiameter,
      nonTargetChangeFromNadir: nonTargetChangeFromNadir[index].sumDiameter,
      /** data from timepoints */
      patientId: timepoint.PatientID,
      date: timepoint.SeriesDate,
      targetResponse: timepoint.targetResponse.result,
      nonTargetResponse: timepoint.nonTargetResponse.result,
      newLesionResponse: timepoint.newLesionResponse.result,
      steroidsUse: timepoint.steroidsUse.result,
      clinicalStatus: timepoint.clinicalStatus.result,
      overallResponse: timepoint.overallResponse.result,
    });
  });
  return dataSource;
}
