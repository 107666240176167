import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';

import {
  Body,
  Container,
  Grid,
  InputBox,
  Select,
  H6,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LoadingSpinner,
  ToggleSwitch,
} from '@platform/ui';
import ExportAlert from './ExportAlert';
import * as models from '../../../modules/dicom-measurement/src';

const { math } = models;
const { round } = math;

export const MeasurementSection = ({
  imageSets,
  selectedStructureSetUID,
  setSelectedStructureSetUID,
  selectedROINumber,
  setSelectedROINumber,
  filteredTrackingType,
  setFilteredTrackingType,
  filteredInterpretedType,
  setFilteredInterpretedType,
}) => {
  const [rois, setRois] = useState([]);
  const [timepoint, setTimepoint] = useState({ ROIMeasurements: [] });
  const [isCalculating, setIsCalculating] = useState(false);
  const [isLongAxis3DCalculated, setIsLongAxis3DCalculated] = useState(false);
  const { seriesStructureMap } = useSelector(state => state.extensions.dicomRT);
  const { PrimaryRTSS: primary, ReferencedRTSS: references } = useSelector(
    state => state.linkage
  );
  const isPrimary = selectedStructureSetUID === primary.SeriesInstanceUID;

  useEffect(() => {
    (async () => {
      setIsCalculating(true);
      setSelectedROINumber(-1);
      await new Promise(resolve => setTimeout(resolve, 0));
      const editModule = cornerstoneTools.getModule('rtstruct-edit');
      const { ROIContours } = editModule.getters.structureSet(
        selectedStructureSetUID
      );
      setRois(ROIContours);
      const uid = models.getSeriesUIDByStructureSetUID(
        selectedStructureSetUID,
        seriesStructureMap
      );
      const imageSet = imageSets.find(set => set.SeriesInstanceUID === uid);
      const timepoint = models.getTimepointWithTool(
        imageSet,
        ROIContours,
        isLongAxis3DCalculated
      );
      setTimepoint(timepoint);
      setIsCalculating(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStructureSetUID, isLongAxis3DCalculated]);

  const onROIUpdate = (ROIContour, key, value) => {
    const newROIContour = _.cloneDeep(ROIContour);
    if (key === 'RTROIInterpretedType') {
      newROIContour.RTROIObservations[key] = value;
      if (value === 'ORGAN') newROIContour.trackingType = 'none';
    } else {
      newROIContour[key] = value;
    }
    const editModule = cornerstoneTools.getModule('rtstruct-edit');
    editModule.setters.updateROI(selectedStructureSetUID, newROIContour);
    const index = rois.findIndex(
      roi => roi.ROINumber === newROIContour.ROINumber
    );
    const newROIContours = [
      ...rois.slice(0, index),
      newROIContour,
      ...rois.slice(index + 1),
    ];
    setRois(newROIContours);
  };

  const longAxis3DMM = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '10px' }}>Long Axis 3D(mm)</span>
      <ToggleSwitch
        isToggled={isLongAxis3DCalculated}
        onToggle={() => setIsLongAxis3DCalculated(!isLongAxis3DCalculated)}
        disabled={isCalculating}
      />
    </div>
  );

  const volumeMM = (
    <span key={'volume'}>
      Volume(mm<sup>3</sup>)
    </span>
  );

  return (
    <Body>
      <Container>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow index={-1}>
              {[
                ['Name', '14%'],
                [longAxis3DMM, '16%'],
                ['Long Axis(mm)', '14%'],
                ['Short Axis(mm)', '14%'],
                [volumeMM, '14%'],
                ['Interpreted Type', '14%'],
                ['Tracking Type', '14%'],
              ].map(([text, width], index) => (
                <TableCell key={index} width={width} header={1}>
                  <H6>{text}</H6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isCalculating && (
            <TableBody>
              {rois
                .filter(
                  roi =>
                    [-1, roi.ROINumber].includes(selectedROINumber) &&
                    [
                      'all',
                      roi.RTROIObservations.RTROIInterpretedType,
                    ].includes(filteredInterpretedType) &&
                    ['all', roi.trackingType].includes(filteredTrackingType)
                )
                .map((roi, index) => {
                  const measurement = timepoint.ROIMeasurements.find(
                    l => l.ROINumber === roi.ROINumber
                  );
                  const longAxis3D = isLongAxis3DCalculated
                    ? round(measurement['longAxis3DValue'], -2)
                    : 'N/A';
                  const longAxis = round(measurement['maxLongAxisValue'], -2);
                  const shortAxis = round(measurement['maxShortAxisValue'], -2);
                  const volume = round(measurement['volume'], -2);
                  return (
                    <Row
                      key={selectedStructureSetUID + roi.ROINumber}
                      index={index}
                      roi={roi}
                      isUpdatable={isPrimary}
                      onROIUpdate={onROIUpdate}
                      longAxis3D={longAxis3D}
                      longAxis={longAxis}
                      shortAxis={shortAxis}
                      volume={volume}
                    />
                  );
                })}
            </TableBody>
          )}
        </Table>
        {isCalculating && (
          <div style={{ margin: '15px 0' }}>
            <LoadingSpinner />
          </div>
        )}
      </Container>
      <div>
        <ExportAlert />
        <Grid container spacing={2}>
          <Grid item xs={3} display="flex">
            <InputBox label={'Scan Date:'} ratio={1}>
              <Select
                value={selectedStructureSetUID}
                onChange={evt => setSelectedStructureSetUID(evt.target.value)}
              >
                {references.map(s => (
                  <option key={s.SeriesInstanceUID} value={s.SeriesInstanceUID}>
                    {s.ReferencedSeriesDate}
                  </option>
                ))}
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={3} display="flex">
            <InputBox label={'ROI Name:'} ratio={1}>
              <Select
                value={selectedROINumber}
                onChange={evt => setSelectedROINumber(Number(evt.target.value))}
              >
                <option key={-1} value={-1}>
                  ------
                </option>
                {rois
                  .filter(
                    roi =>
                      [
                        'all',
                        roi.RTROIObservations.RTROIInterpretedType,
                      ].includes(filteredInterpretedType) &&
                      ['all', roi.trackingType].includes(filteredTrackingType)
                  )
                  .map(roi => (
                    <option key={roi.ROINumber} value={roi.ROINumber}>
                      {roi.ROIName}
                    </option>
                  ))}
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={3}>
            <InputBox label={'Interpreted Type:'} ratio={1}>
              <Select
                value={filteredInterpretedType}
                onChange={evt => setFilteredInterpretedType(evt.target.value)}
              >
                {[
                  ['all', 'All'],
                  ['GTV', 'GTV'],
                  ['CTV', 'CTV'],
                  ['PTV', 'PTV'],
                  ['ORGAN', 'Organ'],
                ].map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={3}>
            <InputBox label={'Tracking Type:'} ratio={1}>
              <Select
                value={filteredTrackingType}
                onChange={evt => setFilteredTrackingType(evt.target.value)}
              >
                {[
                  ['all', 'All'],
                  ['tracked', 'Tracked'],
                  ['new', 'New'],
                  ['ex', 'Ex'],
                ].map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </InputBox>
          </Grid>
        </Grid>
      </div>
    </Body>
  );
};
MeasurementSection.propTypes = {
  imageSets: PropTypes.array,
  defaultStructureSetUID: PropTypes.string,
  selectedStructureSetUID: PropTypes.string,
  setSelectedStructureSetUID: PropTypes.func,
  selectedROINumber: PropTypes.number,
  setSelectedROINumber: PropTypes.func,
  filteredTrackingType: PropTypes.string,
  setFilteredTrackingType: PropTypes.func,
  filteredInterpretedType: PropTypes.string,
  setFilteredInterpretedType: PropTypes.func,
};

const Row = ({
  index,
  roi,
  isUpdatable,
  onROIUpdate,
  longAxis3D,
  longAxis,
  shortAxis,
  volume,
}) => {
  const { ROIName, RTROIObservations, trackingType } = roi;
  const { RTROIInterpretedType } = RTROIObservations;
  const interpretedTypeMap = {
    GTV: 'GTV',
    CTV: 'CTV',
    PTV: 'PTV',
    ORGAN: 'Organ',
  };
  const trackingTypeMap = {
    none: '--------',
    tracked: 'Tracked',
    new: 'New',
    ex: 'Ex',
  };
  const isTumorVolume = ['GTV', 'CTV', 'PTV'].includes(
    roi.RTROIObservations.RTROIInterpretedType
  );
  const isTrackable = ['none', 'tracked', 'new'].includes(roi.trackingType);
  return (
    <TableRow index={index}>
      {[ROIName, longAxis3D, longAxis, shortAxis, volume].map((text, i) => (
        <TableCell key={i}>
          <H6>{text}</H6>
        </TableCell>
      ))}
      <TableCell>
        {isUpdatable && isTrackable ? (
          <Select
            value={RTROIInterpretedType}
            onChange={evt =>
              onROIUpdate(roi, 'RTROIInterpretedType', evt.target.value)
            }
          >
            {Object.entries(interpretedTypeMap).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        ) : (
          <H6>{interpretedTypeMap[RTROIInterpretedType]}</H6>
        )}
      </TableCell>
      <TableCell>
        {isUpdatable && isTumorVolume && isTrackable ? (
          <Select
            value={trackingType}
            onChange={evt => onROIUpdate(roi, 'trackingType', evt.target.value)}
          >
            {Object.entries(trackingTypeMap)
              .filter(([value]) => value !== 'ex')
              .map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
          </Select>
        ) : (
          <H6>{trackingTypeMap[trackingType]}</H6>
        )}
      </TableCell>
    </TableRow>
  );
};
Row.propTypes = {
  index: PropTypes.number,
  roi: PropTypes.shape({
    ROIName: PropTypes.string,
    ROINumber: PropTypes.number,
    RTROIObservations: PropTypes.shape({
      RTROIInterpretedType: PropTypes.string,
    }),
    trackingType: PropTypes.string,
  }),
  isUpdatable: PropTypes.bool,
  onROIUpdate: PropTypes.func,
  longAxis3D: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longAxis: PropTypes.number,
  shortAxis: PropTypes.number,
  volume: PropTypes.number,
};
