/**
 * VIEWPORT
 */
export const SET_VIEWPORT = 'VIEWPORT::SET';
export const SET_VIEWPORT_ACTIVE = 'VIEWPORT::SET_ACTIVE';
export const SET_VIEWPORT_LAYOUT = 'VIEWPORT::SET_LAYOUT';
export const SET_VIEWPORT_LAYOUT_AND_DATA =
  'VIEWPORT::SET_VIEWPORT_LAYOUT_AND_DATA';
export const CLEAR_VIEWPORT = 'VIEWPORT::CLEAR';

/**
 * EXTENSIONS
 */
export const SET_EXTENSION_DATA = 'SET_EXTENSION_DATA';
export const SET_DICOMRT_IMAGE_DATA = 'SET_DICOMRT_IMAGE_DATA';
