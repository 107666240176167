export const BODY_PARTS = {
  BRAIN: 'brain',
  BONE: 'bone',
  LUNG: 'lung',
  LIVER: 'liver',
  LYMPHNODE: 'lymph-node',
  PLEURA: 'pleura',
  PLEURALEFFUSION: 'pleural-effusion',
  OTHERS: 'others',
};

export const TRACKING_TYPES = {
  TARGET: 'target',
  NONTARGET: 'non-target',
  NEW: 'new',
  NOTINCLUDED: 'not-included',
  NONE: 'none',
  ISOLATED: 'isolated',
};

export const OVERALL_RESPONSE = {
  COMPLETE_RESPONSE: 'CR',
  PARTIAL_RESPONSE: 'PR',
  STABLE_DISEASE: 'SD',
  PSEUDO_PROGRESSIVE_DISEASE: 'Pseudo-PD',
  PROGRESSIVE_DISEASE: 'PD',
  NOT_EVALUABLE: 'NE',
  NONE: 'NED',
};

export const TARGET_RESPONSE = {
  COMPLETE_RESPONSE: 'CR',
  PARTIAL_RESPONSE: 'PR',
  STABLE_DISEASE: 'SD',
  PROGRESSIVE_DISEASE: 'PD',
  NOT_EVALUABLE: 'NE',
  NONE: 'NED',
};

export const NON_TARGET_RESPONSE = {
  COMPLETE_RESPONSE: 'CR',
  NON_PROGRESSIVE_RESPONSE: 'Non-CR/Non-PD',
  PROGRESSIVE_DISEASE: 'PD',
  NOT_EVALUABLE: 'NE',
  NONE: 'NED',
};

export const NEW_LESION_RESPONSE = {
  PRESENT: 'Present',
  ABSENT: 'Absent',
  UNCONFIRMED: 'Equivocal',
  NOT_EVALUABLE: 'NE',
  NONE: 'NED',
};

export const MEASUREMENT_TECHNIQUE = {
  UNIDIMENSIONAL: 'UNIDIMENSIONAL',
  BIDIMENSIONAL: 'BIDIMENSIONAL',
  VOLUMETRIC: 'VOLUMETRIC',
};

export const STEROIDS_USE = {
  NONE: 'None',
  DECREASED: 'Decreased',
  STABLE: 'Stable',
  INCREASED: 'Increased',
  NOT_APPLICABLE: 'N/A',
};

export const CLINICAL_STATUS = {
  WORSE: 'Worse',
  STABLE: 'Stable',
  IMPROVED: 'Improved',
  NOT_APPLICABLE: 'N/A',
};

export const BRAIN_LOCATION = {
  L_Frn: 'Left Frontal',
  R_Frn: 'Right Frontal',
  L_Temp: 'Left Temporal',
  R_Temp: 'Right Temporal',
  L_Par: 'Left Parietal',
  R_Par: 'Right Parietal',
  L_Occ: 'Left Occipital',
  R_Occ: 'Right Occipital',
  L_Cere: 'Left Cerebellum',
  R_Cere: 'Right Cerebellum',
  BS: 'Brainstem',
  L_Caud: 'Left Caudate',
  R_Caud: 'Right Caudate',
  L_Puta: 'Left Putamen',
  R_Puta: 'Right Putamen',
  L_Thal: 'Left Thalamus',
  R_Thal: 'Right Thalamus',
  L_GP: 'Left Globus Pallidus',
  R_GP: 'Right Globus Pallidus',
};
