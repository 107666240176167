import { init } from './init';
import {
  signInWithEmailAndPassword,
  resetPassword,
  signInWithGooglePopup,
  signOut,
  getCurrentUser,
  reauthenticateUser,
} from './auth';
import { getUser, getUserRole, createUser } from './firestore/users';
import {
  writeLog,
  writeLogs,
  getSubjectLogs,
  checkAndSubmitLogs,
} from './firestore/logs';
import {
  getProjects,
  getProject,
  createProject,
  updateProject,
  updateProjectMembers,
  deleteProject,
  getDicomstores,
  getDicomstoresAsEditor,
  getDicomstore,
  createDicomstore,
  updateDicomstore,
  updateDicomstoreMembers,
  deleteDicomstore,
  getStudies,
  getStudy,
  createStudy,
  updateStudy,
  getStudyState,
  getAvailableStudyState,
  updateStudyState,
  getOperations,
  getOperation,
  createOperation,
  updateOperation,
  deleteOperation,
  getPatientInfos,
  deletePatientInfo,
} from './firestore/projects';
import {
  getSites,
  getSite,
  getTrials,
  getTrial,
  getTrialCriteria,
  createTrial,
  updateTrial,
  updateTrialMembers,
  deleteTrial,
  getCohorts,
  getCohort,
  createCohort,
  updateCohort,
  deleteCohort,
  getSubjects,
  getSubject,
  createSubject,
  updateSubjectCohorts,
  updateSubjectMembers,
  updateSubjectStatus,
  updateSubjectEndpoints,
  deleteSubject,
  getTasks,
  onTaskSnapshot,
  createTask,
  uploadImages,
  getSeries,
  createSeries,
  getObjects,
  createObject,
  updateObject,
  getSubjectSeries,
  createSubjectSeries,
  getRead,
  getReadAssessments,
  createRead,
  updateRead,
  checkAndUpdateSubjectRead,
  checkAndUpdateSubjectEndpoints,
} from './firestore/trials';
import { uploadFile } from './storage/files';
import { _uuid, standardizeName } from './utils/generator';

export default {
  init,
  /** auth */
  signInWithEmailAndPassword,
  resetPassword,
  signInWithGooglePopup,
  signOut,
  getCurrentUser,
  reauthenticateUser,
  /** firestore-user */
  getUser,
  getUserRole,
  createUser,
  /** audit log */
  writeLog,
  writeLogs,
  getSubjectLogs,
  checkAndSubmitLogs,
  /** firestore-project-mgmt */
  getProjects,
  getProject,
  createProject,
  updateProject,
  updateProjectMembers,
  deleteProject,
  getDicomstores,
  getDicomstoresAsEditor,
  getDicomstore,
  createDicomstore,
  updateDicomstore,
  updateDicomstoreMembers,
  deleteDicomstore,
  getStudies,
  getStudy,
  createStudy,
  updateStudy,
  getStudyState,
  getAvailableStudyState,
  updateStudyState,
  getOperations,
  getOperation,
  createOperation,
  updateOperation,
  deleteOperation,
  getPatientInfos,
  deletePatientInfo,
  /** firestore-trial-mgmt */
  getSites,
  getSite,
  getTrials,
  getTrial,
  getTrialCriteria,
  createTrial,
  updateTrial,
  updateTrialMembers,
  deleteTrial,
  getCohorts,
  getCohort,
  createCohort,
  updateCohort,
  deleteCohort,
  getSubjects,
  getSubject,
  createSubject,
  updateSubjectCohorts,
  updateSubjectMembers,
  updateSubjectStatus,
  updateSubjectEndpoints,
  deleteSubject,
  getTasks,
  onTaskSnapshot,
  createTask,
  uploadImages,
  getSeries,
  createSeries,
  getObjects,
  createObject,
  updateObject,
  getSubjectSeries,
  createSubjectSeries,
  getRead,
  getReadAssessments,
  createRead,
  updateRead,
  checkAndUpdateSubjectRead,
  checkAndUpdateSubjectEndpoints,
  /** storage */
  uploadFile,
  /** utils */
  _uuid,
  standardizeName,
};
