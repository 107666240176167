import { math } from '../../../../modules/dicom-measurement/src';
const { round } = math;

/**
 * @param {Object[]} timepoints
 * @param {Object[]} selectedROIContours
 * @param {Object[]} comparedROIContours
 * @param {Number} criteria.smallDiameterChange
 * @param {Number} criteria.absoluteDiameterChange
 * @param {Object[]} assessments
 */
function getTimepointStats(
  timepoints,
  selectedROIContours,
  comparedROIContours,
  criteria,
  assessments
) {
  const selectedROINumbers = selectedROIContours.map(roi => roi.ROINumber);
  const comparedROINumbers = comparedROIContours.map(roi => roi.ROINumber);

  const stats = timepoints
    .map(timepoint => {
      const lesions = timepoint.Lesions;
      const s = lesions.filter(({ ROINumber }) =>
        selectedROINumbers.includes(ROINumber)
      );
      const c = lesions.filter(({ ROINumber }) =>
        comparedROINumbers.includes(ROINumber)
      );
      /** get summation */
      const v = 'volume';
      const m = 'maxDiameterValue';
      const summation = {
        date: timepoint.SeriesDate,
        sumVolume: round(getSumOfMeasuments(s, v), -2),
        sumDiameter: round(getSumOfMeasuments(s, m), -2),
        comparedSumVolume: round(getSumOfMeasuments(c, v), -2),
        comparedSumDiameter: round(getSumOfMeasuments(c, m), -2),
      };
      return { ...timepoint, summation };
    })
    .map((timepoint, seriesIndex, ts) => {
      /** get change from the baseline */
      const baselineIndex = 0;
      const fromBaseline = getChangeFromTimepoint({
        timepoints: ts,
        selectedROINumbers,
        comparedROINumbers,
        baselineIndex,
        followUpIndex: seriesIndex,
        criteria,
      });

      /** get change from the nadir */
      const findNadir = ts
        .map(t => t.summation)
        .slice(baselineIndex, seriesIndex)
        .reduce(
          (acc, cur, index) =>
            cur.sumDiameter < acc.value
              ? { index: baselineIndex + index, value: cur.sumDiameter }
              : acc,
          { index: seriesIndex, value: Infinity }
        );
      const { index: nadirIndex } = findNadir;
      const fromNadir = getChangeFromTimepoint({
        timepoints: ts,
        selectedROINumbers,
        comparedROINumbers,
        baselineIndex: nadirIndex,
        followUpIndex: seriesIndex,
        criteria,
      });

      /** get change from the latest */
      let latestIndex = seriesIndex - 1;
      latestIndex = latestIndex >= 0 ? latestIndex : 0;
      const fromLatest = getChangeFromTimepoint({
        timepoints: ts,
        selectedROINumbers,
        comparedROINumbers,
        baselineIndex: latestIndex,
        followUpIndex: seriesIndex,
        criteria,
      });

      return {
        date: timepoint.SeriesDate,
        baselineDate: ts[baselineIndex].SeriesDate,
        nadirDate: ts[nadirIndex].SeriesDate,
        latestDate: ts[latestIndex].SeriesDate,
        existROIContours: selectedROIContours.length > 0,
        ROINames: selectedROIContours.map(({ ROIName }) => ROIName),
        comparedROINames: comparedROIContours.map(({ ROIName }) => ROIName),
        summation: timepoint.summation,
        fromBaseline,
        fromNadir,
        fromLatest,
      };
    });
  return stats;
}

/**
 * @param {Object[]} measurements
 * @param {String} key
 */
function getSumOfMeasuments(measurements, key) {
  const sumOfMeasuments = measurements.reduce((acc, cur) => {
    return acc + cur[key];
  }, 0);
  return sumOfMeasuments;
}

/**
 * @param {Object[]} bMeasurements
 * @param {Object[]} fMeasurements
 * @param {String} key
 * @param {Number} criteria.smallDiameterChange
 */
function getSumOfChange(bMeasurements, fMeasurements, key, criteria) {
  let fSumOfChange = 0;
  for (let i = 0; i < bMeasurements.length; i++) {
    if (!bMeasurements[i]) {
      continue;
    }
    const change =
      fMeasurements[i].maxDiameterValue - bMeasurements[i].maxDiameterValue;
    if (Math.abs(change) >= criteria.smallDiameterChange) {
      fSumOfChange =
        fSumOfChange + fMeasurements[i][key] - bMeasurements[i][key];
    }
  }
  return fSumOfChange;
}

/**
 * @param {Number} numerator
 * @param {Number} denominator
 */
function getRatio(numerator, denominator) {
  return denominator === 0 ? 0 : numerator / denominator;
}

/**
 * @param {Number} change
 * @param {Number} sum
 */
function getPercentageChange(change, sum) {
  const relativeChange = sum === 0 ? (change === 0 ? 0 : 1) : change / sum;
  if (relativeChange > 1) {
    return 100;
  } else if (relativeChange < -1) {
    return -100;
  } else {
    return 100 * relativeChange;
  }
}

/**
 * @param {Object[]} bMeasurements
 * @param {Object[]} fMeasurements
 * @param {Number} criteria.absoluteDiameterChange
 */
function checkAbsoluteDiameterChange(bMeasurements, fMeasurements, criteria) {
  let existAbsoluteDiameterChange = false;
  let existAbsoluteSumOfDiameterChange = false;
  let bSumOfDiameter = 0;
  let fSumOfDiameter = 0;
  for (let i = 0; i < bMeasurements.length; i++) {
    if (!bMeasurements[i] || !fMeasurements[i]) {
      continue;
    }
    bSumOfDiameter = bSumOfDiameter + bMeasurements[i].maxDiameterValue || 0;
    fSumOfDiameter = fSumOfDiameter + fMeasurements[i].maxDiameterValue || 0;
    if (
      fMeasurements[i].maxDiameterValue - bMeasurements[i].maxDiameterValue >=
      criteria.absoluteDiameterChange
    ) {
      existAbsoluteDiameterChange = true;
    }
  }
  if (fSumOfDiameter - bSumOfDiameter >= criteria.absoluteSumOfDiameterChange) {
    existAbsoluteSumOfDiameterChange = true;
  }
  return { existAbsoluteDiameterChange, existAbsoluteSumOfDiameterChange };
}

/**
 * @param {Object[]} Timepoints
 * @param {Number[]} selectedROINumbers
 * @param {Number[]} comparedROINumbers
 * @param {Number} baselineIndex
 * @param {Number} followUpIndex
 * @param {Number} criteria.smallDiameterChange
 * @param {Number} criteria.absoluteDiameterChange
 */
function getChangeFromTimepoint({
  timepoints,
  selectedROINumbers,
  comparedROINumbers,
  baselineIndex,
  followUpIndex,
  criteria,
}) {
  if (followUpIndex <= baselineIndex) {
    return {
      initialDate: timepoints[baselineIndex].SeriesDate,
      date: timepoints[followUpIndex].SeriesDate,
      sumVolume: 0,
      sumDiameter: 0,
      comparedSumVolume: 0,
      comparedSumDiameter: 0,
      existAbsoluteDiameterChange: false,
      existAbsoluteSumOfDiameterChange: false,
    };
  }
  const b = timepoints[baselineIndex].Lesions.filter(({ ROINumber }) =>
    selectedROINumbers.includes(ROINumber)
  );
  const cb = timepoints[baselineIndex].Lesions.filter(({ ROINumber }) =>
    comparedROINumbers.includes(ROINumber)
  );
  const f = timepoints[followUpIndex].Lesions.filter(({ ROINumber }) =>
    selectedROINumbers.includes(ROINumber)
  );
  const cf = timepoints[followUpIndex].Lesions.filter(({ ROINumber }) =>
    comparedROINumbers.includes(ROINumber)
  );
  const { summation: baselineSummation } = timepoints[baselineIndex];
  const { sumVolume: sv, sumDiameter: sl } = baselineSummation;
  const cv = getSumOfChange(b, f, 'volume', criteria);
  const cl = getSumOfChange(b, f, 'maxDiameterValue', criteria);
  const ccv = getSumOfChange(cb, cf, 'volume', criteria);
  const ccl = getSumOfChange(cb, cf, 'maxDiameterValue', criteria);
  const {
    existAbsoluteDiameterChange,
    existAbsoluteSumOfDiameterChange,
  } = checkAbsoluteDiameterChange(b, f, criteria);

  const percentageChangeVolume = round(getPercentageChange(cv, sv), -2);
  const percentageChangeDiameter = round(getPercentageChange(cl, sl), -2);
  const proportionalPercentageChangeVolume = round(
    percentageChangeVolume * getRatio(ccv, cv),
    -2
  );
  const proportionalPercentageChangeDiameter = round(
    percentageChangeDiameter * getRatio(ccl, cl),
    -2
  );

  return {
    initialDate: timepoints[baselineIndex].SeriesDate,
    date: timepoints[followUpIndex].SeriesDate,
    sumVolume: percentageChangeVolume,
    sumDiameter: percentageChangeDiameter,
    comparedSumVolume: proportionalPercentageChangeVolume,
    comparedSumDiameter: proportionalPercentageChangeDiameter,
    existAbsoluteDiameterChange: existAbsoluteDiameterChange,
    existAbsoluteSumOfDiameterChange: existAbsoluteSumOfDiameterChange,
  };
}

export default getTimepointStats;
