import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Container,
  Grid,
  Select,
  H6,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@platform/ui';
import { math } from '../../../modules/dicom-measurement/src';
import {
  BODY_PARTS,
  BRAIN_LOCATION,
  TRACKING_TYPES,
} from '../constants/guideline';
import { getTimepoints } from '../models/getTimepoints';
const { round } = math;

export const MeasurementSection = ({
  imageSets,
  ROIContours,
  onROIUpdate,
  getFilteredROIContours,
  filteredBodyPart,
  setFilteredBodyPart,
  filteredTrackingType,
  setFilteredTrackingType,
  selectedLesionNumber,
  setSelectedLesionNumber,
  timepointIndex,
  setTimepointIndex,
}) => {
  const timepoints = getTimepoints(imageSets, ROIContours);
  const filteredROIContours = getFilteredROIContours(ROIContours);

  const onFilteredTrackingTypeChanged = evt => {
    const type = evt.target.value;
    setFilteredTrackingType(type);
    if (type !== 'selected') {
      setSelectedLesionNumber(-1);
    }
  };

  const onFilteredBodyPartChanged = evt => {
    const bodyPart = evt.target.value;
    setFilteredBodyPart(bodyPart);
  };

  const onSelectedLesionNumberChanged = evt => {
    setSelectedLesionNumber(Number(evt.target.value));
  };

  const onTimepointIndexChanged = evt => {
    setTimepointIndex(evt.target.value);
  };

  const VolumeMM = (
    <span key={'volume'}>
      Volume(mm<sup>3</sup>)
    </span>
  );

  return (
    <Body>
      <Container>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow index={-1}>
              {[
                ['No.', '4%'],
                ['Name', '12%'],
                ['Location', '12%'],
                ['Long Axis(mm)', '12%'],
                ['Short Axis(mm)', '12%'],
                [VolumeMM, '12%'],
                ['Measurability', '12%'],
                ['Body Part', '12%'],
                ['Type', '12%'],
              ].map(([text, width]) => (
                <TableCell key={text} width={width} header={1}>
                  <H6>{text}</H6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredROIContours.map((roi, index) => {
              const timepoint = timepoints[timepointIndex];
              const lesion = timepoint.Lesions.find(
                d => d.ROINumber === roi.ROINumber
              );
              const longAxis = round(lesion['maxLongAxisValue'], -2);
              const shortAxis = round(lesion['maxShortAxisValue'], -2);
              const volume = round(lesion['volume'], -2);
              return (
                <Row
                  key={roi.ROINumber}
                  index={index}
                  roi={roi}
                  onROIUpdate={onROIUpdate}
                  longAxis={longAxis}
                  shortAxis={shortAxis}
                  volume={volume}
                />
              );
            })}
          </TableBody>
        </Table>
      </Container>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Select
            value={filteredBodyPart}
            onChange={evt => onFilteredBodyPartChanged(evt)}
          >
            {[
              ['all', 'Whole Body'],
              ['brain', 'Brain'],
              ['liver', 'Liver'],
              ['lung', 'Lung'],
              ['lymph-node', 'Lymph Node'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={filteredTrackingType}
            onChange={evt => onFilteredTrackingTypeChanged(evt)}
          >
            {[
              ['all', 'All Lesions'],
              ['measurable', 'All Measurables'],
              ['target', 'Target Lesions'],
              ['non-target', 'Non-target Lesions'],
              ['new', 'New Lesions'],
              ['selected', 'Selected Lesion'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={selectedLesionNumber}
            onChange={evt => onSelectedLesionNumberChanged(evt)}
          >
            <option key={-1} value={-1}>
              ------
            </option>
            {filteredTrackingType === 'selected' &&
              ROIContours.map(roi => (
                <option key={roi.ROINumber} value={roi.ROINumber}>
                  {roi.ROIName}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={timepointIndex}
            onChange={evt => onTimepointIndexChanged(evt)}
          >
            {timepoints.map((timepoint, index) => (
              <option key={timepoint.SeriesDate + index} value={index}>
                {timepoint.SeriesDate}
              </option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Body>
  );
};
MeasurementSection.propTypes = {
  imageSets: PropTypes.array,
  ROIContours: PropTypes.array,
  onROIUpdate: PropTypes.func,
  getFilteredROIContours: PropTypes.func,
  filteredBodyPart: PropTypes.string,
  setFilteredBodyPart: PropTypes.func,
  filteredTrackingType: PropTypes.string,
  setFilteredTrackingType: PropTypes.func,
  selectedLesionNumber: PropTypes.number,
  setSelectedLesionNumber: PropTypes.func,
  timepointIndex: PropTypes.number,
  setTimepointIndex: PropTypes.func,
};

const Row = ({ index, roi, onROIUpdate, longAxis, shortAxis, volume }) => {
  const { ROIName, measurable, bodyPart, trackingType } = roi;
  const key = Object.keys(BRAIN_LOCATION).find(l => ROIName.includes(l));
  const location = BRAIN_LOCATION[key] || 'N/A';
  const measurability = measurable ? 'measurable' : 'non-measurable';
  const handleBodyPartChange = evt => {
    onROIUpdate(roi.ROINumber, evt.target.value, trackingType);
  };
  const handleTypeChange = evt => {
    onROIUpdate(roi.ROINumber, bodyPart, evt.target.value);
  };

  return (
    <TableRow index={index}>
      {[
        index,
        ROIName,
        location,
        longAxis,
        shortAxis,
        volume,
        measurability,
      ].map((text, i) => (
        <TableCell key={i}>
          <H6>{text}</H6>
        </TableCell>
      ))}
      <TableCell>
        <Select value={bodyPart} onChange={handleBodyPartChange}>
          {[
            [BODY_PARTS.BRAIN, 'Brain'],
            [BODY_PARTS.LIVER, 'Liver'],
            [BODY_PARTS.LUNG, 'Lung'],
            [BODY_PARTS.LYMPHNODE, 'Lymph Node'],
            [BODY_PARTS.PLEURA, 'Pleura'],
            [BODY_PARTS.PLEURALEFFUSION, 'Pleural Effusion'],
            [BODY_PARTS.BONE, 'Bone'],
            [BODY_PARTS.OTHERS, 'Others'],
          ].map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <Select value={trackingType} onChange={handleTypeChange}>
          {[
            [TRACKING_TYPES.TARGET, 'Target'],
            [TRACKING_TYPES.NONTARGET, 'Non-target'],
            [TRACKING_TYPES.NEW, 'New Lesion'],
            [TRACKING_TYPES.NOTINCLUDED, '--------'],
          ].map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};
Row.propTypes = {
  index: PropTypes.number,
  roi: PropTypes.shape({
    ROIName: PropTypes.string,
    ROINumber: PropTypes.number,
    measurable: PropTypes.bool,
    bodyPart: PropTypes.string,
    trackingType: PropTypes.string,
  }),
  onROIUpdate: PropTypes.func,
  longAxis: PropTypes.number,
  shortAxis: PropTypes.number,
  volume: PropTypes.number,
};
