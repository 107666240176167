import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Icon,
  ScrollableArea,
  TableList,
  Grid,
  Box,
  Button,
  IconButton,
  ToolTip,
  Select,
  TableListItem,
} from '@platform/ui';
import usePrimary from '../../../hooks/usePrimary';
import { NewROIForm } from '../forms/NewROIForm';
import { ROIContourItem } from '../sections/ROIContourItem';
import FooterButton from './FooterButton';

export const ROIContourSection = ({
  mode,
  StructureSetUID,
  StructureSetDescription,
  onConfirm,
  ROIContours,
  selectedROIContour,
  getNewROIName,
  addNewROIContour,
  copyROIContour,
  navigateToROIContour,
  onROIClick,
  onROIUpdate,
  onROIDelete,
  isAllDisplayed,
  onDisplayAllStatusChange,
  onComputingStatusChange,
  isLinking,
  onLinkingStatusChange,
  getROILinked,
  onROILink,
  onROIUnlink,
  getROIChecked,
  onROICheck,
}) => {
  const bottomRef = useRef(null);
  const [selectedTrackingType, setSelectedTrackingType] = useState('all');
  const [isScrolling, setIsScrolling] = useState(false);
  const isPrimary = usePrimary();
  const disabled = mode === 'readonly' || (mode === 'tracking' && !isPrimary);

  useEffect(() => {
    if (isScrolling) bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    setIsScrolling(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ROIContours]);

  return (
    <>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: '10px' }}
        >
          <DisplayAllButton
            isAllDisplayed={isAllDisplayed}
            onDisplayAllStatusChange={onDisplayAllStatusChange}
          />
          <NewROIForm
            mode={mode}
            defaultName={getNewROIName()}
            onFinished={rois => {
              addNewROIContour(rois);
              setIsScrolling(true);
            }}
            disabled={disabled || isLinking}
          />
          <ComputeButton
            onComputingStatusChange={onComputingStatusChange}
            disabled={disabled || isLinking}
          />
          {mode === 'tracking' && (
            <LinkButton
              onLinkingStatusChange={onLinkingStatusChange}
              disabled={isLinking}
            />
          )}
        </Box>
        {['tracking', 'longitudinal'].includes(mode) && (
          <TrackingTypeFilter
            selectedTrackingType={selectedTrackingType}
            setSelectedTrackingType={setSelectedTrackingType}
            mode={mode}
          />
        )}
        {['readonly', 'contouring', 'tracking'].includes(mode) && (
          <StructureSetFilter label={StructureSetDescription} />
        )}
        <ScrollableArea>
          <TableList headless style={{ margin: '0 0 100px' }}>
            {ROIContours.filter(roi => {
              switch (selectedTrackingType) {
                case 'all':
                  return true;
                case 'measurable':
                  return ['target', 'non-target'].includes(roi.trackingType);
                case 'lymph-node':
                  return selectedTrackingType === roi.bodyPart;
                case 'organ':
                  return 'ORGAN' === roi.RTROIObservations.RTROIInterpretedType;
                default:
                  return selectedTrackingType === roi.trackingType;
              }
            }).map((roi, idx) => (
              <ROIContourItem
                key={StructureSetUID + roi.ROINumber + roi.trackingType}
                id={StructureSetUID + roi.ROINumber + roi.trackingType}
                idx={idx}
                mode={mode}
                roi={roi}
                selected={roi.ROINumber === selectedROIContour?.ROINumber}
                onConfirm={onConfirm}
                onClick={onROIClick}
                onUpdate={onROIUpdate}
                onDelete={onROIDelete}
                onCopy={(roi, idx) => {
                  copyROIContour(roi, idx);
                  setIsScrolling(true);
                }}
                onNavigate={navigateToROIContour}
                isLinking={isLinking}
                getLinked={getROILinked}
                onUnlink={onROIUnlink}
                getChecked={getROIChecked}
                onCheck={onROICheck}
              />
            ))}
            <div ref={bottomRef} />
          </TableList>
        </ScrollableArea>
      </div>
      {isLinking && (
        <div style={{ margin: 'auto 0 0 0', padding: '5px 0 0' }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FooterButton onClick={() => onROILink(false)}>
                Cancel
              </FooterButton>
            </Grid>
            <Grid item xs={6}>
              <FooterButton onClick={() => onROILink(true)}>Link</FooterButton>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
ROIContourSection.propTypes = {
  mode: PropTypes.string,
  StructureSetUID: PropTypes.string,
  StructureSetDescription: PropTypes.string,
  onConfirm: PropTypes.func,
  ROIContours: PropTypes.array,
  selectedROIContour: PropTypes.object,
  addNewROIContour: PropTypes.func,
  copyROIContour: PropTypes.func,
  navigateToROIContour: PropTypes.func,
  onROIClick: PropTypes.func,
  onROIUpdate: PropTypes.func,
  onROIDelete: PropTypes.func,
  isAllDisplayed: PropTypes.bool,
  onDisplayAllStatusChange: PropTypes.func,
  onComputingStatusChange: PropTypes.func,
  isLinking: PropTypes.bool,
  onLinkingStatusChange: PropTypes.func,
  getROILinked: PropTypes.func,
  onROILink: PropTypes.func,
  onROIUnlink: PropTypes.func,
  getROIChecked: PropTypes.func,
  onROICheck: PropTypes.func,
};

const DisplayAllButton = ({ isAllDisplayed, onDisplayAllStatusChange }) => {
  return (
    <ToolTip title={isAllDisplayed ? 'Hide' : 'Display'}>
      <IconButton
        onClick={() => onDisplayAllStatusChange()}
        style={{ marginRight: '5px' }}
        color="secondary"
        variant="contained"
      >
        <Icon
          name={isAllDisplayed ? 'eye-on' : 'eye-off'}
          width="24px"
          height="24px"
          style={{ color: 'black', background: 'transparent' }}
        />
      </IconButton>
    </ToolTip>
  );
};
DisplayAllButton.propTypes = {
  isAllDisplayed: PropTypes.bool,
  onDisplayAllStatusChange: PropTypes.func,
};

const ComputeButton = ({ onComputingStatusChange, disabled }) => {
  return (
    <Button
      disabled={disabled}
      onClick={() => onComputingStatusChange(true)}
      style={{ marginLeft: '5px' }}
      color="secondary"
      variant="contained"
      fullWidth
    >
      <Icon
        name="code"
        width="20px"
        height="20px"
        style={{ color: disabled ? '#5A5A5A' : 'black' }}
      />
      Calc
    </Button>
  );
};
ComputeButton.propTypes = {
  onComputingStatusChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const LinkButton = ({ onLinkingStatusChange, disabled }) => {
  return (
    <Button
      disabled={disabled}
      onClick={() => onLinkingStatusChange(true)}
      style={{ marginLeft: '5px' }}
      color="secondary"
      variant="contained"
      fullWidth
    >
      <Icon
        name="link"
        width="20px"
        height="20px"
        style={{ color: disabled ? '#5A5A5A' : 'black' }}
      />
      Link
    </Button>
  );
};
LinkButton.propTypes = {
  onLinkingStatusChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const TrackingTypeFilter = ({
  selectedTrackingType,
  setSelectedTrackingType,
  mode,
}) => {
  const mapping = {
    contouring: [],
    tracking: [
      { id: 'all', label: 'All' },
      { id: 'tracked', label: 'Tracked' },
      { id: 'new', label: 'New' },
      { id: 'ex', label: 'Ex' },
      { id: 'organ', label: 'Organ' },
    ],
    longitudinal: [
      { id: 'all', label: 'All Lesions' },
      { id: 'target', label: 'Target Lesions' },
      { id: 'non-target', label: 'Non Target Lesions' },
      { id: 'new', label: 'New Lesions' },
      { id: 'lymph-node', label: 'Lymph Nodes' },
    ],
  };
  const types = mapping[mode];
  return (
    <Select
      value={selectedTrackingType}
      onChange={evt => setSelectedTrackingType(evt.target.value)}
      style={{ margin: '0 0 5px', minHeight: '40px' }}
    >
      {types.map(({ id, label }) => (
        <option key={id} value={id}>
          {label}
        </option>
      ))}
    </Select>
  );
};
TrackingTypeFilter.propTypes = {
  selectedTrackingType: PropTypes.string,
  setSelectedTrackingType: PropTypes.func,
  mode: PropTypes.string,
};

const StructureSetFilter = ({ label }) => {
  return (
    <TableListItem
      itemKey={0}
      itemIndex={''}
      itemClass={''}
      itemMeta={
        <IconButton
          disabled
          onClick={() => {}}
          color="primary"
          size="small"
          style={{ background: 'transparent' }}
        >
          <Icon name={'menu'} width="18px" height="18px" />
        </IconButton>
      }
      itemMetaClass="item-color-section"
      itemSelected={false}
      onItemClick={() => {}}
      style={{ padding: '0 4px 0 0' }}
    >
      {label}
    </TableListItem>
  );
};
StructureSetFilter.propTypes = {
  label: PropTypes.string,
};
