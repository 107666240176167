import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getActiveContexts } from '../store/selectors.js';

export const AppContext = createContext({});

export const CONTEXTS = {
  CORNERSTONE: 'ACTIVE_VIEWPORT::CORNERSTONE',
  VTK: 'ACTIVE_VIEWPORT::VTK',
};

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children, config }) => {
  const activeContexts = useSelector(state => getActiveContexts(state));
  const location = useLocation();
  const mode = getMode(location, config);

  const _servers = [];
  Object.keys(config.servers).forEach(serverType => {
    const endpoints = config.servers[serverType];
    endpoints.forEach(endpoint => {
      const server = Object.assign({}, endpoint);
      server.type = serverType;
      _servers.push(server);
    });
  });
  const activeServer =
    (_servers && _servers.find(a => a.active === true)) || {};

  return (
    <AppContext.Provider
      value={{ appConfig: config, activeContexts, activeServer, mode }}
    >
      {children}
    </AppContext.Provider>
  );
};
AppProvider.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object,
};

export const withAppContext = Component => {
  return function WrappedComponent(props) {
    const { appConfig, activeContexts } = useAppContext();
    return (
      <Component
        {...props}
        appConfig={appConfig}
        activeContexts={activeContexts}
      />
    );
  };
};

function getMode(location, config = {}) {
  const modes = {
    readonly: 'readonly',
    contouring: 'contouring',
    default: config.enableLongitudinalExtension ? 'longitudinal' : 'contouring',
  };
  if (config.readOnly) return 'readonly';
  if (location.pathname.includes('tracking')) return 'tracking';
  const query = new URLSearchParams(location.search);
  return modes[query.get('type')] || modes['contouring'];
}

export default AppContext;
